import Login from "./pages/auth";
import Register from "./pages/auth/Register";
import Formations from "./pages/formation";
import FormationDetails from "./pages/formation/Details";
import Home from "./pages/home";
import Offers from "./pages/offer";
import AddOffer from "./pages/offer/Add";
import OfferDetails from "./pages/offer/Details";
import Profile from "./pages/profile";
import RH from "./pages/rh";
import Services from "./pages/service";

const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/se-connecter",
    component: <Login />,
  },
  {
    path: "/creer-un-compte",
    component: <Register />,
  },
  {
    path: "/profil",
    component: <Profile />,
  },
  {
    path: "/offres",
    component: <Offers />,
  },
  {
    path: "/offres/:offerID",
    component: <OfferDetails />,
  },
  {
    path: "/publier-une-offre",
    component: <AddOffer />,
  },
  {
    path: "/formations",
    component: <Formations />,
  },
  {
    path: "/formations/:formationID",
    component: <FormationDetails />,
  },
  {
    path: "/accompagnement",
    component: <Services />,
  },
  {
    path: "/sous-traitance",
    component: <RH />,
  },
];

export default routes;
