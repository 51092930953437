import { toast } from "react-toastify";

const initialState = {
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "EDIT_PROFIL_LOADING":
      return { ...state, loading: payload };
    case "EDIT_PROFIL_SUCCESS":
      toast.success("Modification réussie");
      return { ...state, loading: false };
    case "EDIT_PROFIL_ERROR":
      return { ...state, loading: false };

    case "UPLOAD_CV_LOADING":
      return { ...state, loading: payload };
    case "UPLOAD_CV_SUCCESS":
      toast.success("CV importé");
      return { ...state, loading: false };
    case "UPLOAD_CV_ERROR":
      return { ...state, loading: false };

    case "APPLY_OFFER_LOADING":
      return { ...state, loading: payload };
    case "APPLY_OFFER_SUCCESS":
      toast.success("Candidature soumise!");
      return { ...state, loading: false };
    case "APPLY_OFFER_ERROR":
      return { ...state, loading: false };

    case "PUBLISH_OFFER_LOADING":
      return { ...state, loading: payload };
    case "PUBLISH_OFFER_SUCCESS":
      toast.success("Votre offre a été envoyé");
      return { ...state, loading: false };
    case "PUBLISH_OFFER_ERROR":
      toast.error("Erreur lors de la soumission de votre offre.");

      return { ...state, loading: false };

    default:
      return state;
  }
};
