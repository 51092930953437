import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCvM6B_WrdmOWD-WcFWnJnLX3rdM7oR8kk",
  authDomain: "human-goal-projet.firebaseapp.com",
  projectId: "human-goal-projet",
  storageBucket: "human-goal-projet.appspot.com",
  messagingSenderId: "638334021365",
  appId: "1:638334021365:web:4f2a3ead0b806186fa1fbc",
  measurementId: "G-M6G6R1LJ0M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
