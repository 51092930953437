import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
//import { offers } from "../../data";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { applyToOffer } from "../../redux/actions/userActions";
import { LoadingButton } from "@mui/lab";

const mapState = ({ user }) => ({
  loading: user.loading,
});

const FormationDetails = () => {
  const params = useParams();
  const [formation, setFormation] = useState({});
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const currentUser = localStorage.getItem("userID");
  const { loading } = useSelector(mapState);
  const theme = useTheme();

  useEffect(() => {
    const docRef = doc(db, "formations", params.formationID);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setFormation({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Box>
      <Container sx={{ py: 8 }}>
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid sx={{ px: 3 }} item xs={12}>
            <Typography textAlign={"center"}>
              <img src={formation && formation.image} width={"100%"} />
            </Typography>
          </Grid>
          <Grid sx={{ mt: 3 }} item xs={12}>
            <Typography variant="h6">{formation && formation.title}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={2}>
          <Grid item sx={12}>
            <Typography textAlign={"justify"}>
              {formation && formation.description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FormationDetails;
