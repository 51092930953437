import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";

const Services = () => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: -5 }}>
      <Grid
        container
        direction={"row-reverse"}
        sx={{
          px: 3,
          py: 5,
          pb: 0,
          backgroundColor: theme.palette.primary.main,
          overflow: "hidden",
        }}
        spacing={2}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Container sx={{ color: "white" }}>
            <Typography variant={"h4"}>
              <span style={{ color: theme.palette.secondary.main }}>
                HumanGoal,{" "}
              </span>
              L'agence qu'il vous faut
            </Typography>
            <Typography>
              Nous sommes des experts en marketing et en communication,
              passionés par la création et le développement de marques uniques
              et de qualité.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/growth.png" width={400} />
          </Typography>
        </Grid>
      </Grid>
      <Container sx={{ p: 3, my: 5 }}>
        <Typography color={"primary"} textAlign={"center"} variant="h4">
          HumanGoal, l'agence de branding qui vous accompagne dans la création
          et le développement de votre marque.
        </Typography>
        <Typography sx={{ my: 1 }} textAlign={"center"}>
          Vous avez un projet?, Une idée, une vision? vous voulez créer ou
          développer votre marque ou votre entreprise? vous cherchez une agence
          de branding qui vous offre des solutions personnalisées et innovantes?
        </Typography>
      </Container>

      <Box sx={{ p: 5, backgroundColor: "whitesmoke" }}>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={7}>
              <Box sx={{ p: 3, backgroundColor: "white", height: 200 }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Graphisme
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Nous créons votre logo, votre charte graphique, vos supports
                    visuels, vos illustrations UI et UX pour donner vie à votre
                    identité visuelle.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ p: 3, backgroundColor: "white", height: 200 }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Animation
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Nous réalisons vos animations 2D, 3D, vos motion designs,
                    vos montages publicitaires, vos photographies pour dynamiser
                    votre image et votre message.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 3, backgroundColor: "white", height: 200 }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Développement
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Nous concevons vos applications mobiles, web, vos systèmes
                    de gestion pour optimiser votre fonctionnement et votre
                    performance.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={{ p: 3, backgroundColor: "white", height: 200 }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Accompagnement et développement de projets
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Nous vous conseillons et vous guidons dans la définition et
                    la mise en œuvre de votre stratégie marketing et de vos
                    contenus pour renforcer votre positionnement et votre
                    notoriété.
                  </Typography>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container sx={{ p: 3, my: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography color={"primary"} variant="h4">
                Notre différence
              </Typography>
              <Typography sx={{ my: 1 }} textAlign={"justify"}>
                Nous ne sommes pas une simple agence de branding. Nous sommes
                votre partenaire, votre allié, votre complice. Nous vous aidons
                à réaliser vos rêves, à créer votre propre histoire, à atteindre
                vos objectifs. Nous nous impliquons dans votre projet , nous
                vous comprenons, nous vous apportons des solutions sur-mesure et
                innovantes. Nous créons et renforçons le lien entre vous et
                votre public, en vous faisant bénéficier de notre créativité, de
                notre passion et de notre professionnalisme.{" "}
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src="/images/product.png" width={200} />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Grid
        sx={{ p: 3, my: 5, backgroundColor: "whitesmoke" }}
        direction={"row-reverse"}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Container>
            <Typography color={"primary"} textAlign={"center"} variant="h4">
              Contactez-nous
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ my: 2 }}
              textAlign={"center"}
            >
              Vous êtes interessé par nos services? vous voulez en savoir plus
              sur notre agence? vous avez des questions ou des remarques?
              <br />
              N'hésitez pas à nous contacter! Nous serons ravis de vous répondre
              et de vous accompagner dans votre projet.
            </Typography>
            <Typography textAlign={"center"}>
              <Button
                variant="outlined"
                sx={{ borderRadius: 10 }}
                startIcon={<CallIcon />}
                href="tel:+243835982139"
              >
                Contactez-nous
              </Button>
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  );
};

export default Services;
