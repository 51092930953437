export const colors = {
  primary: "#2470a2",
  secondary: "#ff5a5a",
  error: "",
  success: "",
  textPrimary: "#151515",
  textSecondary: "#6E6E6E",
  bgColor: "#eee",
  paper: "#fff",
};
