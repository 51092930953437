import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import theme from "../theme";

export const socials = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=61556749576311&mibextid=JRoKGi",
    icon: <FacebookIcon sx={{ color: "white" }} />,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/humangoal_drc",
    icon: <InstagramIcon sx={{ color: "white" }} />,
  },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/company/humangoal/",
    icon: <LinkedInIcon sx={{ color: "white" }} />,
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/",
  //   icon: <TwitterIcon sx={{ color: "white" }} />,
  // },
];

export const informations = [
  {
    title: "A propos de nous",
    href: "/a-propos",
  },
  {
    title: "Foires aux questions (FAQ) ",
    href: "/faq",
  },
  {
    title: "Politique de confidentialité",
    href: "/politique-de-confidentialité",
  },
  {
    title: "Mentions légales",
    href: "/mentions-légales",
  },
  {
    title: "Conditions générales d'utilisation",
    href: "/cgu",
  },
];

export const offers = [
  {
    id: 1,
    title: "Comptable Provincial-USAID/RDC Apprendre Tout Ensemble",
    poste: "Education Development Center",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
  },
  {
    id: 2,
    title: "Comptable Provincial-USAID/RDC Apprendre Tout Ensemble",
    poste: "Education Development Center",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
  },
  {
    id: 3,
    title: "Comptable Provincial-USAID/RDC Apprendre Tout Ensemble",
    poste: "Education Development Center",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
  },
];

export const formations = [
  {
    id: 1,
    title: "Formation1",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
  {
    id: 2,
    title: "Formation2",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
  {
    id: 3,
    title: "Formation3",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
  {
    id: 4,
    title: "Formation4",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
  {
    id: 5,
    title: "Formation3",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
  {
    id: 6,
    title: "Formation4",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora eaque voluptates et aliquam quasi! Reprehenderit aliquid sed ut illo quam expedita hic, consequuntur suscipit? Saepe libero corrupti incidunt voluptate officia.",
    image: "",
  },
];
