export const menuList = [
  {
    name: "Offres d'emploi",
    href: "/offres",
  },
  {
    name: "Publier une offre",
    href: "/publier-une-offre",
  },
  {
    name: "Formations",
    href: "/formations",
  },
  {
    name: "Services",
    href: "/sous-traitance",
  },
  {
    name: "Branding Agency",
    href: "/accompagnement",
  },
];
