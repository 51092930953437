import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";

const RH = () => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: -5 }}>
      <Grid
        container
        direction={"row-reverse"}
        sx={{
          px: 3,
          py: 5,
          pb: 0,
          backgroundColor: theme.palette.primary.main,
          overflow: "hidden",
        }}
        spacing={2}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Container sx={{ color: "white" }}>
            <Typography variant={"h4"}>
              <span style={{ color: theme.palette.secondary.main }}>
                HumanGoal,{" "}
              </span>
              pour une bonne gestion des ressources humaines.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/approved.png" width={400} />
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ p: 3, backgroundColor: "whitesmoke" }}>
        <Container sx={{ p: 3, my: 5 }}>
          <Typography
            color={"primary"}
            sx={{ mb: 5 }}
            textAlign={"center"}
            variant="h4"
          >
            Services
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box sx={{ p: 3, backgroundColor: "white" }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Sous-traitance RH
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Les ressources humaines sont une fonction stratégique de
                    l’entreprise. Or beaucoup de missions clés pèsent
                    aujourd’hui sur les épaules des RH. <br /> C’est pourquoi
                    HumanGoal propose de prendre en charge du début à la fin la
                    gestion de votre service RH avec pour avantage de :
                    recruter, bâtir et renforcer la marque employeur, gérer
                    l’onboarding, assurer les formations des équipes, développer
                    le bien-être au travail, la gestion de la paie,
                    l’accompagnement de la mobilité interne… <br /> En effet,
                    les fondateurs d’HumanGoal, ayant une expertise et ayant
                    exercé sur le plan national comme international sur plus
                    d’une décennie dans la gestion des ressources humaines et de
                    la paie, notre groupe vous garantit une prise en charge
                    totale de votre service des ressources humaines avec la
                    partie paie et administration du personnel.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 3, backgroundColor: "white" }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Recrutement
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Parce que le recrutement est l’une des principales clés
                    stratégiques d’une entreprise, à HumanGoal nous nous
                    engageons à vous orienter et à vous soutenir de bout-en-bout
                    lors du processus de recrutement afin d’optimiser votre
                    prise de décision.
                    <br />
                    En parallèle, nous tenons à fournir aux candidats désireux
                    d’acquérir une expertise ou de se perfectionner, un ensemble
                    de parcours de formations dispensés par des spécialistes et
                    consultants nationaux et internationaux de premier ordre.
                    Nous confier votre recrutement, c'est choisir un recrutement
                    de qualité d’autant plus qu’il est fait par nos spécialistes
                    et consultants nationaux et internationaux.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 3, backgroundColor: "white" }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Comptabilité
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Vous gérez la comptabilité de votre entreprise seul.e mais
                    commencez à être un peu sous l’eau et pensez à faire appel à
                    un expert-comptable ? Nous sommes là pour vous. <br /> Et La
                    mission principale de notre cabinet d’expertise-comptable
                    est d’accompagner ses clients sur l’ensemble de leurs
                    problématiques financières et fiscales, de la tenue
                    comptable à l’audit en passant par le conseil en gestion.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 3, backgroundColor: "white" }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Audit et contrôle
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Les audits sont des opérations qui permettent aux
                    entreprises de contrôler leur santé financière. Et notre
                    mission d’audit consiste en l’analyse de la situation d’une
                    entreprise à un temps T. <br /> Le but est de mettre en
                    lumière les forces de cette dernière, mais également ses
                    différents points d’amélioration. <br /> Et HumanGoal
                    propose comme missions au sein de votre entreprise :
                    Réaliser des entretiens avec les collaborateurs clés de la
                    société, contrôler les comptes, les factures et documents
                    financiers de de votre entreprise, évaluer la santé
                    financière de votre entreprise ainsi que son capital,
                    rédiger un rapport dans lequel nous soulignons les points
                    forts et les points faibles de votre entreprise, Faire des
                    recommandations. En effet, nos professionnels ont des
                    connaissances en comptabilité, en gestion des risques, en
                    finances et en économie. Ils ont une grande capacité
                    d’adaptation leur permettant de faire face à toutes les
                    situations avec diplomatie.
                  </Typography>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 3, backgroundColor: "white" }}>
                <Container>
                  <Typography variant="h5" color={"secondary"}>
                    Fiscalité
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    Faire face à des problèmes fiscaux peut être très complexe
                    et stressant pour les particuliers ou les entreprises.
                    <br /> C’est dans ce contexte que nous voulons jouer un rôle
                    essentiel. Notre cabinet a des experts qui peuvent vous
                    aider à déterminer les obligations fiscales, à analyser et à
                    résoudre les problèmes fiscaux, ainsi qu’à minimiser les
                    risques de litige. <br /> Ce que nous vous proposons c’est
                    de conseiller les clients sur les enjeux fiscaux,
                    représenter les clients dans les litiges fiscaux, élaborer
                    des stratégies fiscales, assurer la conformité fiscale,
                    surveiller les développements fiscaux Nous sommes là pour
                    fournir une expertise inestimable en matière de fiscalité
                    d’entreprise afin d’aider les entreprises à atteindre leurs
                    objectifs fiscaux tout en respectant les lois et règlements
                    en vigueur.
                  </Typography>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Grid
        sx={{ p: 3, my: 5, backgroundColor: "whitesmoke" }}
        direction={"row-reverse"}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Container>
            <Typography color={"primary"} textAlign={"center"} variant="h4">
              Contactez-nous
            </Typography>
            <Typography textAlign={"center"} sx={{ my: 2 }}>
              Vous êtes interessé par nos services? vous voulez en savoir plus
              sur notre agence? vous avez des questions ou des remarques?
              <br />
              N'hésitez pas à nous contacter! Nous serons ravis de vous répondre
              et de vous accompagner dans votre projet.
            </Typography>
            <Typography textAlign={"center"}>
              <Button
                variant="outlined"
                sx={{ borderRadius: 10 }}
                startIcon={<CallIcon />}
                href="tel:+243835982139"
              >
                Contactez-nous
              </Button>
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  );
};

export default RH;
