import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, loginWithGoogle } from "../../redux/actions/authActions";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";

const mapState = ({ auth }) => ({
  loading: auth.loading,
});

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({});
  const { loading } = useSelector(mapState);

  const redirect = () => navigate("/profil");

  const handleChange = (e) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(credentials, redirect));
  };

  const handleLoginWithGoogle = () => dispatch(loginWithGoogle(redirect));

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Logo width={150} />
        </Grid> */}
        <Grid sx={{ marginTop: -2 }} item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ fontWeight: "600" }}
            variant="h4"
          >
            Se connecter
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="E-mail"
            required
            fullWidth
            variant="standard"
            type="email"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password"
            label="Mot de passe"
            fullWidth
            required
            variant="standard"
            type="password"
            onChange={handleChange}
          />
        </Grid>

        <Grid
          container
          direction={"row"}
          justifyContent={"flex-end"}
          item
          xs={12}
        >
          <Typography component={"a"} href="/mot-de-passe-oublie">
            Mot de passe oublié?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ color: "#fff", borderRadius: 10 }}
            color="primary"
            fullWidth
          >
            Connexion
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"}>Ou</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleLoginWithGoogle}
            variant="outlined"
            sx={{ borderRadius: 10 }}
            color="primary"
            fullWidth
            startIcon={<GoogleIcon />}
          >
            Connexion avec Google
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography color={"textSecondary"} textAlign={"center"}>
            Vous n'avez pas de compte?{" "}
            <a href="/creer-un-compte">Créer un compte</a>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;
