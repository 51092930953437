import { LoadingButton } from "@mui/lab";
import { Container, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/authActions";
import { auth } from "../../firebase";
import { Navigate, useNavigate } from "react-router-dom";

const mapState = ({ auth }) => ({
  loading: auth.loading,
});

const Account = () => {
  const { loading } = useSelector(mapState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => dispatch(logOut(() => navigate("/se-connecter")));

  return (
    <Container sx={{ p: 5 }}>
      <Grid container spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          item
          xs={12}
        >
          <LoadingButton
            loading={loading}
            sx={{ borderRadius: 10, width: "50%" }}
            onClick={handleLogOut}
          >
            {" "}
            Déconnexion
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Account;
