import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import routes from "./routes";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "./redux/actions/authActions";

const mapState = ({ auth }) => ({
  currentUser: auth.currentUser,
});

function App() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(saveUser(user.uid));
      }
    });
  }, []);

  return (
    <Layout>
      <Routes>
        {routes.map((route) => (
          <Route exact path={route.path} element={route.component} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
