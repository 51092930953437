import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { menuList } from "../header/menuList";
import { informations, socials } from "../../data";
import { useNavigate } from "react-router-dom";
import Call from "@mui/icons-material/Call";
import Mail from "@mui/icons-material/Mail";

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: "white",
      }}
    >
      <Container>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} md={3}>
            <Typography>
              <img src="/images/icon_humanGoal_B.png" width={200} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Typography fontWeight={"700"} variant="h5">
                Navigation
              </Typography>
              <List dense>
                {menuList.map((item) => (
                  <Button
                    sx={{ display: "block" }}
                    color="inherit"
                    component="a"
                    href={item.href}
                  >
                    {item.name}
                  </Button>
                ))}
              </List>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Typography fontWeight={"700"} variant="h5">
                Contact
              </Typography>
              <Typography>
                <Button sx={{ color: "white" }} startIcon={<Call />}>
                  + 243 835 982 139
                </Button>
              </Typography>
              <Typography>
                <Button sx={{ color: "white" }} startIcon={<Mail />}>
                  contact@human-goal.com
                </Button>
              </Typography>
              <Typography>
                Concession COTEX N° 63 <br />
                Avenue Colonel Mondjiba <br />
                Kinshasa République démocratique du Congo
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={3}>
            <Container>
              <Typography fontWeight={"700"} variant="h5">
                Suivez-nous
              </Typography>
              {socials.map((item) => (
                <IconButton component="a" href={item.link}>
                  {item.icon}
                </IconButton>
              ))}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
