import React from "react";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import LoginForm from "../../components/auth/Form";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useSelector } from "react-redux";

const mapState = ({ auth }) => ({
  currentUser: auth.currentUser,
});

function Login() {
  const { currentUser } = useSelector(mapState);

  //if (!currentUser && currentUser)
  return (
    <Grid container sx={{ backgroundColor: "#fff" }} spacing={2}>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        sx={{ height: window.innerHeight }}
        xs={12}
      >
        <Paper
          elevation={0}
          sx={{
            p: 5,
            width: 500,
          }}
        >
          <LoginForm />
        </Paper>
      </Grid>
    </Grid>
  );
  //return <Navigate to={"/profil"} replace={true} />;
}

export default Login;
