import { Box, Button, Container, Grid, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/includes/SearchBar";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import "moment/locale/fr";
import parse from "html-react-parser";

const Offers = () => {
  const [offers, setOffers] = useState(null);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const colRef = collection(db, "offers");
    const q = query(
      colRef,
      where("status", "==", "validated"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setOffers(content);
        setData(content);
      }
    });

    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      offers &&
      offers.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  const removeHtmlTags = (html) => {
    const text = document.createElement("div");
    text.innerHTML = html;
    return text.textContent || text.innerText; // Extract text content
  };

  return (
    <Box sx={{ backgroundColor: "#eee" }}>
      <Container>
        <Grid container spacing={1} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="h4" sx={{ my: 3 }}>
              {" "}
              Offres d'emploi
            </Typography>
            <Box>
              <SearchBar
                autoFocus={true}
                searchFilter={searchFilter}
                placeholder={"Rechercher une offre..."}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ p: 3 }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          direction={"row"}
          spacing={1}
        >
          <Grid container spacing={1} item xs={12} md={4}>
            <Grid item xs={12} md={12}>
              <Box sx={{ p: 3, backgroundColor: "#fff" }}>
                <Typography>Type de contrat</Typography>
                <List dense>
                  <Button sx={{ display: "block" }}>
                    CDD (durée déterminée){" "}
                  </Button>
                  <Button sx={{ display: "block" }}>
                    CDI (durée indéterminée)
                  </Button>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ p: 3, backgroundColor: "#fff" }}>
                <Typography>Temps de travail</Typography>
                <List dense>
                  <Button sx={{ display: "block" }}>Temps plein</Button>
                  <Button sx={{ display: "block" }}>Temps partiel</Button>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12} md={8}>
            <Grid item xs={8}>
              <Typography
                sx={{ backgroundColor: "#fff", p: 1 }}
                textAlign={"center"}
              >
                Offre d'emploi: {search && search}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{ backgroundColor: "#fff", p: 1 }}
                textAlign={"center"}
              >
                {data && data.length} résultat(s)
              </Typography>
            </Grid>
            {data &&
              data.map((offer) => (
                <Grid key={offer.id} item xs={12}>
                  <Box sx={{ p: 3, backgroundColor: "#fff" }}>
                    <Typography color={"primary"} variant="h5">
                      {offer.title}
                    </Typography>
                    <Typography variant="h6" color={"secondary"}>
                      {offer.poste}
                    </Typography>
                    <Typography color={"text.secondary"}>
                      {removeHtmlTags(offer.description).slice(0, 250)}
                    </Typography>
                    <Typography sx={{ my: 1 }}>
                      <Button sx={{ borderRadius: 10 }}>
                        {moment(offer.createdAt).fromNow()}
                      </Button>
                      <Button
                        href={"/offres/" + offer.id}
                        variant="default"
                        sx={{ borderRadius: 10, mx: 2 }}
                      >
                        Postuler maintenant
                      </Button>
                    </Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Offers;
