import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import SearchBar from "../includes/SearchBar";

const HomeBanner = () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: 8,
        pb: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha(theme.palette.primary.main, 0.4)}
      ), url('/images/mainBanner.jpg')`,
        height: 400,
      }}
    >
      <Grid item sx={{ color: "white" }} xs={12}>
        <Container>
          <Typography variant="h4" sx={{ mt: 8, px: { md: 5, xs: 0 } }}>
            Des offres qui correspondent à votre profil
          </Typography>
          <Box sx={{ px: { md: 5, xs: 0 }, py: 2 }}>
            <SearchBar placeholder={"Rechercher une offre"} />
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HomeBanner;
