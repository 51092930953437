import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export const editProfil = (data) => {
  return (dispatch) => {
    const currentUser = auth.currentUser.uid;
    const docRef = doc(db, "users", currentUser);

    dispatch({ type: "EDIT_PROFIL_LOADING", payload: true });

    updateDoc(docRef, {
      ...data,
    })
      .then(() => {
        dispatch({ type: "EDIT_PROFIL_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "EDIT_PROFIL_ERROR", payload: err.message });
      });
  };
};

export const uploadCV = (cv) => {
  return (dispatch) => {
    const storage = getStorage();
    const currentUser = auth.currentUser.uid;
    const storageRef = ref(storage, "cv/" + currentUser + ".pdf");
    const metadata = {
      contentType: "application/pdf",
    };

    const docRef = doc(db, "users", currentUser);
    const uploadTask = uploadBytesResumable(storageRef, cv, metadata);

    dispatch({ type: "UPLOAD_CV_LOADING", payload: true });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateDoc(docRef, {
            cv: downloadURL,
          })
            .then(() => {
              dispatch({ type: "UPLOAD_CV_SUCCESS" });
            })
            .catch((err) => {
              dispatch({ type: "UPLOAD_CV_ERROR", error: err.message });
            });
        });
      }
    );
  };
};

export const applyToOffer = (offerID, user) => {
  return (dispatch) => {
    const currentUser = auth.currentUser.uid;
    const docRef = doc(db, "offers", offerID);

    dispatch({ type: "APPLY_OFFER_LOADING", payload: true });

    updateDoc(docRef, {
      userIDs: arrayUnion(currentUser),
      users: arrayUnion(user),
    })
      .then(() => {
        dispatch({ type: "APPLY_OFFER_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "APPLY_OFFER_ERROR", payload: err.message });
      });
  };
};

export const publishOffer = (offer) => {
  return (dispatch) => {
    dispatch({ type: "PUBLISH_OFFER_LOADING", payload: true });

    const colRef = collection(db, "offers");

    addDoc(colRef, {
      ...offer,
      createdAt: new Date().getTime(),
      status: "init",
    })
      .then(() => {
        dispatch({ type: "PUBLISH_OFFER_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "PUBLISH_OFFER_ERROR" });
      });
  };
};
