import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import HomeBanner from "../../components/home/Banner";

const Home = () => {
  return (
    <main>
      <HomeBanner />
      <Container>
        <Grid container spacing={2} sx={{ p: 3, my: 3 }}>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <Box>
              <Typography variant="h4">Trouvez l'emploi idéal</Typography>
              <Typography sx={{ my: 2 }}>
                Notre plateforme innovante utilise une technologie de pointe
                pour vous connecter directement aux meilleures opportunités
                professionnelles.
              </Typography>
              <Typography>
                <Button
                  component="a"
                  href="/offres"
                  sx={{ borderRadius: 10 }}
                  variant="outlined"
                >
                  Rechercher une offre
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src="/images/find.png" width={400} />
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ p: 3 }} direction={"row-reverse"}>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <Box>
              <Typography variant="h4">Recrutement du personnel</Typography>
              <Typography sx={{ my: 2 }}>
                En utilisant notre plateforme innovante, vous pourrez simplifier
                votre processus de recrutement et obtenir des résultats
                exceptionnels en un temps record.
              </Typography>
              <Typography>
                <Button
                  component="a"
                  href="/publier-une-offre"
                  sx={{ borderRadius: 10 }}
                  variant="outlined"
                >
                  Publier une offre
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src="/images/upload.png" width={400} />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Home;
