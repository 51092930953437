import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { auth } from "../../firebase";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { editProfil } from "../../redux/actions/userActions";

const mapState = ({ user }) => ({
  loading: user.loading,
});

const Edit = () => {
  const currentUser = auth.currentUser;
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editProfil(user));
  };

  return (
    <Container sx={{ p: 5 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            item
            xs={12}
          >
            <Avatar
              sx={{ width: 100, height: 100 }}
              component={"p"}
              src={currentUser && currentUser.photoURL}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              name="displayName"
              label="Nom complet"
              fullWidth
              type="text"
              variant="standard"
              placeholder={currentUser && currentUser.displayName}
              sx={{ width: { md: "50%", xs: "100%" } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              name="phoneNumber"
              label="Numéro de téléphone"
              placeholder={currentUser && currentUser.phoneNumber}
              fullWidth
              type="text"
              variant="standard"
              sx={{ width: { md: "50%", xs: "100%" } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              name="about"
              label="A propos de moi"
              placeholder={user && user.about}
              fullWidth
              type="text"
              variant="standard"
              sx={{ width: { md: "50%", xs: "100%" } }}
              multiline
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              <LoadingButton
                type="submit"
                sx={{
                  width: { md: "50%", xs: "100%" },
                  mt: 4,
                  borderRadius: 10,
                }}
                fullWidth
                variant="contained"
                loading={loading}
              >
                Valider
              </LoadingButton>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Edit;
