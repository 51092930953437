import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
//import { offers } from "../../data";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { applyToOffer } from "../../redux/actions/userActions";
import { LoadingButton } from "@mui/lab";
import parse from "html-react-parser";

const mapState = ({ user }) => ({
  loading: user.loading,
});

const OfferDetails = () => {
  const params = useParams();
  const [offer, setOffer] = useState({});
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const currentUser = localStorage.getItem("userID");
  const { loading } = useSelector(mapState);
  const navigate = useNavigate();

  useEffect(() => {
    const docRef = doc(db, "offers", params && params.offerID);
    const userDocRef = currentUser && doc(db, "users", currentUser);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setOffer({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    const getUser =
      currentUser &&
      onSnapshot(
        userDocRef,
        (snapshot) => {
          if (snapshot.data()) {
            setUser({ ...snapshot.data(), id: snapshot.id });
          }
        },
        (err) => {
          console.log(err.message);
        }
      );

    return () => {
      unsubscribe();
      currentUser && getUser();
    };
  }, []);

  const existUser = (users, userID) =>
    userID && users && users.find((user) => user === userID);

  const handleApply = () => {
    currentUser
      ? dispatch(applyToOffer(offer.id, user))
      : navigate("/se-connecter");
  };

  return (
    <Box>
      <Container sx={{ py: 8 }}>
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid sx={{ px: 3 }} item xs={12}>
            <Typography textAlign={"center"}>
              <img src="/images/logo.png" width={200} />
            </Typography>
          </Grid>
          <Grid sx={{ mt: 3 }} item xs={12}>
            <Typography variant="h6">{offer && offer.title}</Typography>
            <Typography color={"text.secondary"}>
              {offer && offer.business}
            </Typography>
            <Box>
              <Button
                sx={{ color: "black" }}
                startIcon={<PlaceIcon color="primary" />}
              >
                Kinshasa
              </Button>
              <Button
                sx={{ color: "black", mx: 1 }}
                startIcon={<DescriptionIcon color="primary" />}
              >
                {offer && offer.contratType}
              </Button>
              <Button
                sx={{ color: "black" }}
                startIcon={<HistoryIcon color="primary" />}
              >
                {offer && offer.workTime}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={2}>
          <Grid item sx={12}>
            <Typography textAlign={"justify"}>
              {offer && offer.description && parse(offer && offer.description)}
            </Typography>

            <Typography color={"text.secondary"}>
              {offer && offer.business}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              {existUser(offer.userIDs, currentUser && currentUser) ? (
                <Button variant="outlined" sx={{ px: 6, borderRadius: 10 }}>
                  Vous avez postulé à cette offre
                </Button>
              ) : (
                <LoadingButton
                  loading={loading}
                  sx={{ px: 6, borderRadius: 10 }}
                  variant="contained"
                  onClick={handleApply}
                >
                  Postuler
                </LoadingButton>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {/* <Box sx={{ backgroundColor: "#eee" }}>
        <Container>
          <Grid container sx={{ p: 5 }} spacing={1}>
            <Grid sx={{ my: 3 }} item xs={12}>
              <Typography variant="h4">Offers similaires</Typography>
            </Grid>
            {offers.map((offer) => (
              <Grid key={offer.id} item xs={12}>
                <Box sx={{ p: 3, backgroundColor: "#fff" }}>
                  <Typography color={"primary"} variant="h5">
                    {offer.title}
                  </Typography>
                  <Typography variant="h6" color={"secondary"}>
                    {offer.poste}
                  </Typography>
                  <Typography color={"text.secondary"}>
                    {offer.details}
                  </Typography>
                  <Typography sx={{ my: 1 }}>
                    <Button sx={{ borderRadius: 10 }}>Il y a 2 jours</Button>
                    <Button
                      href={"/offres/" + offer.id}
                      variant="default"
                      sx={{ borderRadius: 10, mx: 2 }}
                    >
                      Postuler maintenant
                    </Button>
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
    </Box>
  );
};

export default OfferDetails;
