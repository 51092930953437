import React from "react";
import Footer from "./footer";
import { Box, Container, CssBaseline } from "@mui/material";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <Box>
      <CssBaseline />
      <Header />
      {children}

      <Footer />
    </Box>
  );
};

export default Layout;
