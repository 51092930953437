import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/includes/SearchBar";
import { db } from "../../firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Formations = () => {
  const theme = useTheme();
  const [formations, setFormations] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const colRef = collection(db, "formations");
    const q = query(colRef, orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setFormations(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      formations &&
      formations.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  return (
    <Box>
      <Grid
        container
        direction={"row-reverse"}
        sx={{
          px: 3,
          pt: 3,
          pb: 0,
          backgroundColor: theme.palette.primary.main,
          overflow: "hidden",
        }}
        spacing={2}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Container sx={{ color: "white" }}>
            <Typography variant="h4">Formations</Typography>
            <Typography>
              Notre objectif est de vous aider à identifier vos besoins et vous
              proposer des solutions sur-mesure afin d’augmenter les compétences
              de vos collaborateurs et les transformer en top performer. C’est
              aussi, mettre en place avec vous une dynamique de succès en vous
              apportant un accompagnement adéquat et en accord avec le plan de
              ce que l’entreprise souhaite mettre en place en termes de
              formation pour ses collaborateurs.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/formation.png" width={300} />
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ backgroundColor: "#eee" }}>
        <Container>
          <Grid container spacing={1} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <Typography textAlign={"center"} variant="h4" sx={{ my: 3 }}>
                {" "}
                Formez-vous aux compétences professionnelles
              </Typography>
              <Typography textAlign={"center"} sx={{ my: 3 }}>
                En formation et en entreprise ! C’est la méthode de formation
                que HumanGoal propose à ses apprenants. Une approche qui place
                les apprenants tout de suite en situation professionnelle
                réelle, au plus près des besoins et conditions de travail dans
                une entreprise avec la possibilité de vous trouver les heures de
                stage.
              </Typography>
              <Box>
                <SearchBar
                  searchFilter={searchFilter}
                  placeholder={"Rechercher une formation..."}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ p: 3 }}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            direction={"row"}
            spacing={1}
          >
            <Grid sx={{ p: 3 }} item xs={12}>
              <Typography variant="h6">
                {formations && formations.length} formations disponibles
              </Typography>
            </Grid>
            {data &&
              data.map((formation) => (
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Formation image"
                      height="140"
                      image={formation.image}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {formation.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formation.description.slice(0, 150)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        onClick={() => navigate("/formations/" + formation.id)}
                        size="small"
                      >
                        En savoir plus
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Formations;
