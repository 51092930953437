import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { publishOffer } from "../../redux/actions/userActions";

const mapState = ({ user }) => ({
  user: user.loading,
});

const AddOffer = () => {
  const { loading } = useSelector(mapState);
  const [offer, setOffer] = useState({});
  const dispatch = useDispatch();
  const workTime = ["Temps plein", "Temps partiel"];
  const contrat = ["CDI", "CDD", "Stage", "Volontariat", "Intérim"];

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(publishOffer(offer));
  };

  const handleEditorChange = (value) => {
    setOffer({ ...offer, description: value });
  };

  const handleChange = (e) =>
    setOffer({ ...offer, [e.target.name]: e.target.value });

  return (
    <Box>
      <Container>
        <Grid sx={{ p: 3 }} container spacing={2}>
          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              <img src="/images/upload.png" width={400} />
            </Typography>
            <Typography textAlign={"center"} variant="h4">
              {" "}
              Trouvez votre prochaine recrue
            </Typography>
            <Typography textAlign={"center"} color={"text.secondary"}>
              Publiez vos offres d'emploi sur Human Goal et recrutez les
              meilleurs candidats.
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ px: 3, py: 5 }} spacing={2}>
          <Grid
            direction={"row"}
            justifyContent={"center"}
            container
            item
            xs={12}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="poste"
                    label="Poste"
                    placeholder="ex: Comptable"
                    required
                    fullWidth
                    type="text"
                    //variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="contratType"
                    label="Type de contrat"
                    required
                    fullWidth
                    type="text"
                    //variant="standard"
                    onChange={handleChange}
                    select
                  >
                    {contrat &&
                      contrat.map((type) => (
                        <MenuItem value={type}>{type} </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="workTime"
                    label="Temps de travail"
                    required
                    fullWidth
                    type="text"
                    // variant="standard"
                    onChange={handleChange}
                    select
                  >
                    {workTime &&
                      workTime.map((type) => (
                        <MenuItem value={type}>{type} </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="business"
                    label="Nom de la société"
                    required
                    fullWidth
                    type="text"
                    //variant="standard"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="website"
                    label="Site web"
                    required
                    fullWidth
                    type="text"
                    // variant="standard"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <TextField
                    name="email"
                    label="Adresse e-mail"
                    required
                    fullWidth
                    type="text"
                    //variant="standard"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <ReactQuill
                    theme="snow"
                    value={offer.description}
                    onChange={handleEditorChange}
                    style={{ height: 200, width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography textAlign={"center"}>
                    <LoadingButton
                      type="submit"
                      sx={{
                        mt: 6,
                        borderRadius: 10,
                      }}
                      variant="contained"
                      loading={loading}
                    >
                      Soummettre
                    </LoadingButton>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddOffer;
